import React from 'react';
import styles from './InfoBlock.module.scss';

const InfoBlock = () => {
  return (
    <section className={styles.infoBlock}>
      <div className={styles.infoBlock__cover}>
        <p>Образовательная платформа ШКОЛКОВО</p>
        <ul>
          <li>
            <a href="/files/Жизненный%20цикл%20ПО%20Школково.pdf" download>
              Жизненный цикл
            </a>
          </li>
          <li>
            <a href="/files/Инструкция%20по%20установке%20ПО%20Школково.pdf" download>
              Инструкция по подключению к платформе
            </a>
          </li>
          <li>
            <a href="/files/Руководство%20пользователя%20ПО%20Школково.pdf" download>
              Руководство пользователя
            </a>
          </li>
        </ul>
      </div>
      <div className={`${styles.infoBlock__cover} ${styles.infoBlock__cover_theme_transparent}`}>
        <p>Расчёт стоимости</p>
        <ul>
        <li>
            Актуальную стоимость услуг можно узнать на странице:
            {' '}
            <a href="https://3.shkolkovo.online/courses-list">
              Каталог онлайн курсов подготовки к ЕГЭ, ОГЭ и олимпиадам
            </a>
          </li>
        </ul>
        <div className={styles.infoBlock__contacts}>
          <div>
            <p>Телефон</p>
            <a href="tel:+78003029279">8 (800) 302-92-79</a>
          </div>
          <div>
            <p>Электронная почта</p>
            <a href="mailto:info@shkolkovo.net" className="email">info@shkolkovo.net</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoBlock;
