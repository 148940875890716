import Footer from "./components/Footer/Footer";
import Content from "./components/Content/Content";
import Direction from "./components/Direction/Direction";
import Header from "./components/Header/Header";
import Contacts from "./components/Contacts/Contacts";
import FeedBack from "./components/FeedBack/FeedBack";
import Banner from "./components/Banner/Banner";
import InfoBlock from "./components/InfoBlock/InfoBlock";

import styles from './App.module.scss'

function App() {

  return (
    <div className={styles.app}>
      <div className={styles.cover}>
        <Header/>
        <Contacts/>
      </div>
      <Banner />
      <div className={styles.cover}>
        <Content/>
        {/*<Direction/>*/}
        <FeedBack/>
        <InfoBlock/>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
