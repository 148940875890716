import React from 'react';
import Data from "../../datas/data";
import styles from './Content.module.scss'

const Content = () => {

  const {
    Description,
    PCDescription,
    PCTitle,
    Software,
    Questions: {
      QTitle,
      List
    },
  } = Data;

  return (
    <section className={styles.content}>
      {/*<h1>{Title}</h1>*/}
      {/*<p>{Description}</p>*/}
      <div>
        <p>{Description.Title}</p>
        <ul>
          {Description.List.map((item) => <li className={styles.normal}>{item}</li>)}
        </ul>
        <p>{Description.Footer}</p>
      </div>
      {/*<h2>{Software.Title}</h2>*/}
      {/*<p>{Software.Subtitle}</p>*/}

      <h2>{PCTitle}</h2>
      {/*<p>{Software.Subtitle}</p>*/}
      {/*<h2>{QTitle}</h2>*/}
      {
        List.map((item, index) => (
          <>
            <p className={styles.bold}><span className={styles.arrow}/>{item.Title}</p>
            <p>{item.Description}</p>
          </>
        ))
      }
    </section>
  );
};

export default Content;
