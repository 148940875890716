const Data = {
  Title: 'Образовательная платформа ШКОЛКОВО',
  Description: {
    Title: 'ПО «Образовательная платформа ШКОЛКОВО» – является программным обеспечением для подготовки к экзаменам, олимпиадам и вступительным испытаниям (ДВИ). ПО объединяет все необходимые подходы к обучению.',
    List: [],
    Footer: ''
  },
  // Software: {
  //   Title: 'Описание программного обеспечения\n',
  //   Subtitle: '«Образовательная платформа ШКОЛКОВО» (далее - Школково) представляет собой интернет сервис (On-cloud), в котором объединены все необходимые инструменты для эффективной подготовке и повышения знаний обучающимся, в зависимости от выбранного тарифа подписки для подготовки к экзаменам, олимпиадам и вступительным испытаниям (ДВИ).\n'
  // },
  PCTitle: 'Функциональные характеристики программного обеспечения\n',
  PCDescription: [
    {
      Title: 'Подготовка к экзаменам, олимпиадам и ДВИ\n',
      Description: 'Платформа предоставляет доступ к образовательным курсам, разработанным преподавателями, которые направлены на всестороннее изучение общеобразовательных,  экзаменационных и олимпиадных материалов.\n'
    },
    {
      Title: 'Помощь в обучении и повышение уровня знаний\n',
      Description: 'Благодаря собранным вместе инструментам на платформе Школково, где проводятся и хранятся онлайн-занятия, работает система проверки и выдачи домашних заданий, а также располагается база задач. Пользователи, получившие  курс, могут освоить даже самый сложный материал.\n'
    },
    {
      Title: 'Отслеживание уровня успеваемости\n',
      Description: 'Образовательная платформа ШКОЛКОВО мотивирует учеников к обучению. Полная аналитика решенных задач и статистика успеваемости, подкрепленная кураторской помощью, позволяет работать над пробелами в знаниях.\n'
    },
    {
      Title: 'Индивидуальный подход и поддержка в обучении\n',
      Description: 'Разбор возникающих вопросов и трудностей в освоении, происходит в онлайн-формате. Ученик курса может задать интересующий вопрос не только в текстовом чате, но и во время проведения видеочата (отслушки), где эксперт будет готов дополнительно объяснить материал и навести на правильное решение.\n'
    },
    {
      Title: 'Доступность образовательной программы\n',
      Description: 'Обучение происходит в онлайн-формате, позволяя ученикам заниматься из любой точки мира, используя современные устройства, в т.ч. компьютеры, ноутбуки, планшеты и мобильные телефоны.\n'
    },
  ],

  Questions: {
    QTitle: 'Задачи, решаемые с помощью ШКОЛКОВО',
    List: [
      // {
      //   Title: 'Подготовка к экзаменам, олимпиадам и ДВИ\n',
      //   Description: 'Платформа предоставляет доступ к образовательным курсам, разработанным преподавателями, которые направлены на всестороннее изучение общеобразовательных,  экзаменационных и олимпиадных материалов.\n'
      // },
      // {
      //   Title: 'Помощь в обучении и повышение уровня знаний\n',
      //   Description: 'Благодаря собранным вместе инструментам на платформе Школково, где проводятся и хранятся онлайн-занятия, работает система проверки и выдачи домашних заданий, а также располагается база задач. Пользователи, получившие  курс, могут освоить даже самый сложный материал.\n'
      // },
      // {
      //   Title: 'Отслеживание уровня успеваемости\n',
      //   Description: 'Образовательная платформа ШКОЛКОВО мотивирует учеников к обучению. Полная аналитика решенных задач и статистика успеваемости, подкрепленная кураторской помощью, позволяет работать над пробелами в знаниях.\n'
      // },
      // {
      //   Title: 'Индивидуальный подход и поддержка в обучении\n',
      //   Description: 'Разбор возникающих вопросов и трудностей в освоении, происходит в онлайн-формате. Ученик курса может задать интересующий вопрос не только в текстовом чате, но и во время проведения видеочата (отслушки), где эксперт будет готов дополнительно объяснить материал и навести на правильное решение.\n'
      // },
      // {
      //   Title: 'Доступность образовательной программы\n',
      //   Description: 'Обучение происходит в онлайн-формате, позволяя ученикам заниматься из любой точки мира, используя современные устройства, в т.ч. компьютеры, ноутбуки, планшеты и мобильные телефоны.\n'
      // },

      {
        Title: 'ПО предоставляет доступ к образовательным курсам, разработанным преподавателями, которые направлены на всестороннее изучение общеобразовательных, экзаменационных и олимпиадных материалов;\n',
        Description: ''
      },
      {
        Title: 'ПО позволяет пользователям получить помощь в обучении и повышение уровня знаний;\n',
        Description: ''
      },
      {
        Title: 'В ПО работает система проверки и выдачи домашних заданий, а также располагается база задач. Пользователи, получившие курс, могут освоить даже самый сложный материал;\n',
        Description: ''
      },
      {
        Title: 'В ПО есть возможность отслеживания уровня успеваемости;\n',
        Description: ''
      },
      {
        Title: 'ПО позволяет отслеживать полную аналитику решенных задач и статистику успеваемости, подкрепленную кураторской помощью, а также позволяет работать над пробелами в знаниях;\n',
        Description: ''
      },
      {
        Title: 'ПО позволяет использовать индивидуальный подход и поддержку в обучении;\n',
        Description: ''
      },
      {
        Title: 'ПО позволяет разбирать возникающие вопросы и трудности в освоении с кураторами и экспертами;\n',
        Description: ''
      },
      {
        Title: 'ПО обеспечивает возможность просмотра и чтения контента, включая онлайн-трансляции и записи прошедших вебинаров;\n',
        Description: ''
      },
      {
        Title: 'Скачивание материалов курсов.\n',
        Description: ''
      },
    ]
  }
}

export default Data